
$(window).on('load', function () {
      $('.masonry').masonry({
        // options
        itemSelector: '.item'
      });
 });
 
$(document).ready(function(){

	$(".cover").each(function( index ) {
		if(	$(this).attr("data-image")	){
			var image = $(this).data("image");
			$(this).css("background-image","url("+image+")");
		
		}
	});
	$("#main").fitVids();
	

	/*
	forms
	*/
	if($(".ajaxForms" ).length>0){
		
		
		
		
		
		/* send to ajax */	
		   $(".ajaxForms").submit(function(e){
		       e.preventDefault();
			   Materialize.updateTextFields();
			  
		   	
		       var form = $(this);
			   var cible= $(this).attr("action");
	
		            $.post(form.attr('action'), form.serialize(), function(data){
		               //var data = $.parseJSON(data);
	
			                if(data.status === true){
			                   console.log("ok");
	
			                }else{
			                	  console.log(data.status);
			                }
	
	
		            });
		        return false;
		   });
		   
		   
	 }
	 
	 $('.js-scrollTo').on('click', function() { // Au clic sur un élément
	 			var page = $(this).attr('href'); // Page cible
	 			var speed = 750; // Durée de l'animation (en ms)
	 			$('html, body').animate( { scrollTop: $(page).offset().top }, speed ); // Go
	 			return false;
	 });

	
	
	



});





