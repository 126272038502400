/*
	script to change color of logo and lunch actions 
	Ce script determine la div par le haut de celle ci 
	du coup le footer risque de ne jamais être actif
	une seule div active a la fois
	necessite une function base() qui correspond al'etat de base avant les animations
	*/
	var actualsection = "";
	
	function base_default(){
		if (isFunction("base")) {
			base();
		}
	}
	
	function isFunction(functionToCheck) {
		if (typeof window[functionToCheck] === "function"){
			return true;
		}else{ 
			return false;
		}
	}
	
	function scroll_position() {
			
			
			var scrollBottom =  $(document).outerHeight( true );
			var botm = scrollBottom - ($(window).height());	
	
			$( "main section, footer" ).each(function( index ) {
								
				var actualscrollTop = $(window).scrollTop(),
				actualscrollBottom = $(window).scrollTop() + $(window).height(),
				posTop =  $(this).offset().top,
				posBottom = posTop + $(this).outerHeight( true );
								
				/* if ofset top == ou > scroll top */
				if( 
					actualscrollTop >posTop    && actualscrollTop < posBottom  || 
					actualscrollTop ==posTop  
									
				){

					actualsection = $(this).attr("id");
					
					
					
					if(actualsection!=""){
						
						if ($(this).data('function')) {
							console.log("1 - "+actualsection);
							var fctn_name = $(this).data('function');
							
							console.log("3 - "+ fctn_name+" - " + isFunction(fctn_name));
							
							if (isFunction(fctn_name)) {
								var fnctn =  fctn_name + "()";
								eval(fnctn);
								console.log("2 - "+actualsection);
								
							}
						}else{
							base_default();
						}
					}else{
						base_default();
					}
					
										
				}
			});
	
			return actualsection;

		}
		
	
	
	$(window).on("load",function(){
		
		/* initie la fonction au scroll */
		$(window).scroll(function() { 
			scroll_position();
		});
		
		/* initie la fonction au chargement de la page */
		scroll_position();
		
		
	});	
		
		
